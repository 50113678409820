import React, { useState } from "react";

import { default as ps } from "../assets/ps.png";
import { default as ai } from "../assets/ai.png";
import { default as pr } from "../assets/pr.png";
import { default as xd } from "../assets/xd.png";
import { default as fig } from "../assets/fig.png";
import { default as p } from "../assets/p.png";
import { default as ma } from "../assets/ma.png";

import Heading from "./Heading";

const softwareItem = [
  { id: "4", img: xd, title: "XD" },
  { id: "5", img: fig, title: "Figma" },
  { id: "6", img: p, title: "Prottapp" },
  { id: "7", img: ma, title: "Maze" },
  { id: "1", img: ps, title: "Photoshop" },
  { id: "2", img: ai, title: "Illustrator" },
  { id: "3", img: pr, title: "Premiere Pro" },
];

const SoftwareProficiency = () => {
  const [software] = useState(softwareItem);
  return (
    <section className="software-proficiency py-20">
      <Heading title="Software Proficiency" />
      <div className="flex flex-row flex-wrap md:flex-nowrap justify-between items-center mt-14 md:px-32">
        {software.map(({ id, img, title }) => (
          <div
            className="software-container flex flex-col w-1/2 md:w-full justify-center items-center mb-3"
            data-aos="zoom-in"
            key={id}
          >
            <img src={img} alt={title} className="w-auto md:w-1/2 mb-3" />
            <p className="text-sm font-medium">{title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SoftwareProficiency;
