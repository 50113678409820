import { useState } from "react";
import { default as portfolio1 } from "../assets/portfolio-1.png";
import { default as portfolio2 } from "../assets/portfolio-2.png";
import { default as portfolio3 } from "../assets/portfolio-3.png";
import ButtonContainer from "./ButtonContainer";
import Heading from "./Heading";

const portfolioItem = [
  {
    id: "1",
    image: portfolio3,
    title: "portfolio 1",
    to: "https://www.behance.net/navaneekrishna17",
  },
  {
    id: "2",
    image: portfolio2,
    title: "portfolio 2",
    to: "https://www.behance.net/navaneekrishna17",
  },
  {
    id: "3",
    image: portfolio1,
    title: "portfolio 3",
    to: "https://www.behance.net/navaneekrishna17",
  },
];

const Portfolio = () => {
  const [portfolio] = useState(portfolioItem);
  return (
    <section className="portfolio py-20 md:px-24" id="portfolio">
      <Heading title="Portfolio" />
      <div className="image-container mt-14 w-full flex flex-col md:flex-row md:justify-evenly items-center">
        {portfolio.map(({ id, image, title, to }) => (
          <div
            className="card cursor-pointer rounded-lg mb-8 md:hover:shadow-xl transform md:hover:scale-105 md:transition-all md:duration-500 md:ease-in-out"
            data-aos="fade-up"
            key={id}
            onClick={() => window.open(to, "_blank")}
          >
            <img src={image} alt={title} className="w-auto" />
          </div>
        ))}
      </div>
      <div className="button-container text-center">
        <ButtonContainer
          title="view all"
          onClick={() =>
            window.open("https://www.behance.net/navaneekrishna17", "_blank")
          }
        />
      </div>
    </section>
  );
};

export default Portfolio;
