import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-scroll";

import { default as navlogo } from "../assets/n-logo.svg";
import {
  faBehance,
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const menuItem = [
  { id: "1", name: "Home", to: "home" },
  { id: "2", name: "Portfolio", to: "portfolio" },
  { id: "3", name: "Skills", to: "skills" },
  { id: "4", name: "Experience", to: "experience" },
  { id: "5", name: "Education", to: "experience" }
];

const contactItem = [
  {
    id: "1",
    icon: faLinkedinIn,
    color: "#1D6EB7",
    link: "https://www.linkedin.com/in/navaneetha-krishnan-72760a20/",
  },
  {
    id: "2",
    icon: faTwitter,
    color: "#26B5E9",
    link: "https://twitter.com/navaneeg",
  },
  {
    id: "3",
    icon: faBehance,
    color: "#000743",
    link: "https://www.behance.net/navaneekrishna17",
  },
  {
    id: "4",
    icon: faFacebookF,
    color: "#0061DE",
    link: "https://www.facebook.com/navaneetha.krishnan.1829/",
  },
];

const Header = () => {
  const [menu] = useState(menuItem);
  const [contactMenu] = useState(contactItem);

  const [click, setClick] = useState(false);

  //   methods
  const onClickBars = () => setClick(!click);

  return (
    <nav className="navbar bg-primary px-4 md:px-32 md:flex md:flex-row md:justify-between md:items-center sticky top-0 z-50 shadow-sm">
      <div className="navbar-brand flex flex-row justify-between items-center pt-4 pb-3">
        <a href="#">
          <img src={navlogo} alt="navbar logo" className="w-7" />
        </a>
        <a className="navbar-bars md:hidden" onClick={onClickBars}>
          <FontAwesomeIcon
            icon={click ? faTimes : faBars}
            className="text-3xl text-white"
          />
        </a>
      </div>
      <div
        className={`navbar-menu text-white absolute bg-primary w-full h-screen md:h-auto transition-all ease-in-out duration-200 ${
          click ? "left-0" : "-left-full"
        } px-3 md:relative md:left-0 md:w-auto md:flex md:flex-row md:items-center`}
      >
        <ul className="md:flex md:flex-row md:mr-3">
          {menu.map(({ id, name, to }) => (
            <li
              className="navbar-item py-4 md:px-3"
              key={id}
              onClick={onClickBars}
            >
              <Link
                activeClass="active"
                to={to}
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}
                className="navbar-link block text-sm md:font-thin hover:text-lightgray cursor-pointer"
                onClick={onClickBars}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
        <div className="contact-icon my-5 w-1/2 flex flex-row justify-between">
          {contactMenu.map(({ id, icon, color, link }) => (
            <a
              href={link}
              target="_blank"
              key={id}
              className="w-8 h-8 bg-white rounded-full flex justify-center items-center md:mr-2"
            >
              <FontAwesomeIcon
                icon={icon}
                className="text-base"
                style={{ color: color }}
              />
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Header;
