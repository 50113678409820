import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { default as userImage } from "../assets/user-img.png";
import { default as shadeImage } from "../assets/rectangle.png";

const Main = () => {
  return (
    <section className="bg-primary w-full pt-16 pb-52 main" id="home">
      <div className="w-auto flex flex-col justify-center items-center md:pr-28 md:pl-1 md:flex md:flex-row md:justify-evenly">
        {/* heading */}
        <div className="heading text-white" data-aos="fade-right">
          <p className="text-2xl md:text-3xl font-light">Hi</p>
          <h2 className="text-4xl md:text-5xl my-3">I'm Navaneeth</h2>
          <div className="text-sm md:text-base font-thin text-lightwhite leading-normal">
            <p>Team Lead - UI Design</p>
            <p>Looking for UI/UX Position</p>
            <p>Hosur, Tamil Nadu</p>
            <div className="mt-6">
              <p className="mb-4">
                <FontAwesomeIcon icon={faEnvelope} className="mr-3" />{" "}
                <a href="mailto:write2navaneeth@gmail.com">
                  write2navaneeth@gmail.com
                </a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhoneAlt} className="mr-3" />
                <a href="tel:09341441428">+91 9341441428</a>
              </p>
            </div>
          </div>
        </div>
        {/* profile image */}
        <div
          className="hidden md:inline-block relative z-40"
          data-aos="zoom-in"
        >
          <img src={userImage} alt="userImage" className="w-3/4" />
        </div>
      </div>
      <img
        src={shadeImage}
        alt="shade"
        className="absolute right-0 top-44 w-1/3 h-1/3 z-10 hidden md:block"
        data-aos="fade-left"
      />
    </section>
  );
};

export default Main;
