import { useState } from "react";

import { default as img1 } from "../assets/Informationarchitecture.png";
import { default as img2 } from "../assets/paperdigital.png";
import { default as img3 } from "../assets/persona.png";
import { default as img4 } from "../assets/scope.png";
import { default as img5 } from "../assets/stories.png";
import { default as img6 } from "../assets/uidesign.png";
import { default as img7 } from "../assets/usabilitytesting.png";
import { default as img8 } from "../assets/userflow.png";
import { default as img9 } from "../assets/userresearch.png";

const skillsItem = [
  { id: "1", image: img9, title: "User Research" },
  { id: "2", image: img3, title: "Persona Creation" },
  { id: "3", image: img5, title: "Write User Stories" },
  { id: "4", image: img4, title: "Prepare Scope Document" },
  { id: "5", image: img8, title: "Create User Flow" },
  { id: "6", image: img1, title: "Build Information Architecture" },
  { id: "7", image: img2, title: "Paper & Digital Prototypes" },
  { id: "8", image: img7, title: "Usability Testing" },
  { id: "9", image: img6, title: "UI Design" },
];

const Skills = () => {
  const [skills] = useState(skillsItem);
  return (
    <section className="skills bg-creamwhite pt-10 md:pt-0" id="skills">
      <div className="heading text-center flex flex-col justify-center items-center md:hidden">
        <h3 className="text-2xl text-lightblack font-medium mb-1">Skills</h3>
        <div className="w-10 h-0.5 bg-brown" />
      </div>
      <div className="flex flex-row">
        <div
          className="bg-primary w-2/6 py-60 rounded-tr-full rounded-br-full hidden md:block"
          // data-aos="fade-right"
        >
          <h4 className="text-5xl font-medium text-white text-right mr-40">
            Skills
          </h4>
        </div>
        <div className="skills-icons-container mx-auto py-10 md:py-3 md:w-2/5 grid grid-cols-3 gap-10 md:gap-0 items-center">
          {skills.map(({ id, image, title }) => (
            <div className="flex flex-col justify-center">
              <div
                className="w-20 h-20 rounded-full bg-orange flex justify-center items-center"
                data-aos="zoom-in"
                key={id}
              >
                <img src={image} alt={title} className="w-auto" />
              </div>
              <p className="font-medium text-xs w-20 mt-2 text-center">
                {title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
