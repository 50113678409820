import React from "react";
import { Parallax } from "react-parallax";
import {
  Education,
  Experience,
  Header,
  Main,
  Portfolio,
  Skills,
  SoftwareProficiency,
} from "../components";

import { default as parallaximg } from "../assets/parallax.png";

const Home = () => {
  return (
    <>
      <Header />
      <Main />
      <Experience />
      <Portfolio />
      <Skills />
      <Parallax bgImage={parallaximg} strength={200}>
        <div className="md:h-80 h-48 w-full overflow-x-hidden"></div>
      </Parallax>
      <SoftwareProficiency />
      <Education />
    </>
  );
};

export default Home;
