import React from "react";

const Heading = ({ title }) => {
  return (
    <div className="heading text-center flex flex-col justify-center items-center">
      <h3 className="text-2xl text-lightblack font-medium mb-1">{title}</h3>
      <div className="w-12 h-0.5 bg-brown" />
    </div>
  );
};

export default Heading;
