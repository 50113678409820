import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBehance,
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { default as experienceImg } from "../assets/experience.png";
import { default as educationImg } from "../assets/education.png";
import { default as outlineImg } from "../assets/outline.png";

import ButtonContainer from "./ButtonContainer";

const experienceItem = [
  {
    id: "1",
    company: "Your Practice Online India Pvt Ltd.,",
    location: "Bangalore",
    joining: "October 2010 – December 2020",
    exp: "10 Years 3 Months",
    designation: "Team Lead - Graphic Designer",
  },
  {
    id: "2",
    company: "Greynium Information Technologies Pvt. Ltd.,",
    location: "Bangalore",
    joining: "January 2006 – September 2010",
    exp: "4 Years 9 Months",
    designation: "Senior Graphic Designer",
  },
  {
    id: "3",
    company: "Media Research Centre,",
    location: "Coimbatore",
    joining: "October 2004 – December 2005",
    exp: "1 Year 3 Months",
    designation: "Graphic Designer",
  },
];

const educationItem = [
  {
    id: "1",
    name: "Master of Science(Media)(2006-2008)",
    year: "2006 – 2008",
    university: "Bharathiar University, Coimbatore, Tamil Nadu",
  },
  {
    id: "2",
    name: "Bachelor of Science (Visual Communication)",
    year: "2003 – 2006",
    university: "Bharathiar University, Coimbatore, Tamil Nadu",
  },
  {
    id: "3",
    name: "Diploma in Mechanical Engineering",
    year: "1994 - 1997",
    university: "Government Polytechnic, Coimbatore",
  },
  {
    id: "4",
    name: "S.S.L.C",
    year: "1993-1994",
    university: "T.A.R.C. Higher Sec., School, Coimbatore",
  },
];

const contactItem = [
  {
    id: "1",
    icon: faLinkedinIn,
    color: "#1D6EB7",
    link: "https://www.linkedin.com/in/navaneetha-krishnan-72760a20/",
  },
  {
    id: "2",
    icon: faTwitter,
    color: "#26B5E9",
    link: "https://twitter.com/navaneeg",
  },
  {
    id: "3",
    icon: faBehance,
    color: "#000743",
    link: "https://www.behance.net/navaneekrishna17",
  },
  {
    id: "4",
    icon: faFacebookF,
    color: "#0061DE",
    link: "https://www.facebook.com/navaneetha.krishnan.1829/",
  },
];

const ExperienceCard = ({ experience }) => (
  <div className="w-full px-3 md:w-2/6 md:px-0" data-aos="fade-up">
    <h4 className="text-black md:text-white text-xl ml-5 mb-5">Experience</h4>
    <div className="card p-8 pb-0 bg-lightgray border-4 border-white rounded-xl">
      {experience?.map(
        ({ id, company, location, joining, exp, designation }) => (
          <div className="flex flex-row" key={id}>
            {/* dot and ruller */}
            <div className="flex flex-col justify-center items-center">
              <div className="dot w-4 h-4 bg-primary border-2 border-white rounded-full"></div>
              <div
                className={`ruler w-0.5 h-32 ${
                  id === "3" ? "bg-transparent" : "bg-bittergray"
                }`}
              ></div>
            </div>
            <div className="content ml-5">
              <p className="text-primary font-bold text-sm mb-1">
                {company} <span className="font-thin">{location}</span>
              </p>
              <p className="text-sm leading-relaxed">{joining}</p>
              <p className="text-xs mb-1">{exp}</p>
              <p className="text-xs">{designation}</p>
            </div>
          </div>
        )
      )}
      <div className="flex flex-row justify-center items-center">
        <img src={experienceImg} alt="experience image" className="w-4/5" />
      </div>
    </div>
  </div>
);

const EducationCard = ({ education }) => (
  <div
    className="w-full px-3 mt-10 md:mt-0 md:w-2/6 md:px-0"
    data-aos="fade-up"
  >
    <h4 className="text-black md:text-white text-xl ml-5 mb-5">Education</h4>
    <div className="card p-8 pb-0 bg-lightgray border-4 border-white rounded-xl">
      {education?.map(({ id, name, year, university }) => (
        <div className="flex flex-row" key={id}>
          {/* dot and ruller */}
          <div className="flex flex-col justify-center items-center">
            <div className="dot w-4 h-4 bg-primary border-2 border-white rounded-full"></div>
            <div
              className={`ruler w-0.5 h-24 ${
                id === "4" ? "bg-transparent" : "bg-bittergray"
              }`}
            ></div>
          </div>
          <div className="content ml-5">
            <p className="text-primary font-bold text-sm mb-1">{name}</p>
            <p className="text-sm leading-relaxed">{year}</p>
            <p className="text-xs">{university}</p>
          </div>
        </div>
      ))}
      <div className="flex flex-row justify-center items-center">
        <img src={educationImg} alt="experience image" className="w-3/5" />
      </div>
    </div>
  </div>
);

const Education = () => {
  const [experience] = useState(experienceItem);
  const [education] = useState(educationItem);
  const [contactMenu] = useState(contactItem);

  return (
    <section className="education" id="experience">
      <div className="flex flex-col md:flex-row justify-evenly items-center pt-14">
        <ExperienceCard experience={experience} />
        <EducationCard education={education} />
      </div>
      <div
        className="py-12 md:py-20 md:px-40 flex flex-col md:flex-row justify-center md:justify-start items-center"
        data-aos="zoom-in"
      >
        <img
          src={outlineImg}
          alt="outline image"
          className="w-10 mt-1 mr-4 hidden md:block"
        />
        <h3 className="text-2xl text-lightblack font-medium">
          Language Proficiency
        </h3>
        <div className="mx-4 my-2 md:my-0" />
        <ButtonContainer title="English" pointer={false} />
        <div className="mx-4 my-2 md:my-0" />
        <ButtonContainer title="Tamil" pointer={false} />
        <div className="mx-4 my-2 md:my-0" />
        <ButtonContainer title="Kannada" pointer={false} />
        <div className="mx-4 my-2 md:my-0" />
        <ButtonContainer title="Telugu" pointer={false} />
      </div>
      <div
        className="footer bg-primary w-full pt-8 pb-10 flex flex-col justify-center items-center"
        data-aos="zoom-in"
      >
        <div className="contact-icon my-5 w-full px-10 md:w-1/6 md:px-0 flex flex-row justify-between">
          {contactMenu.map(({ id, icon, color, link }) => (
            <a
              href={link}
              target="_blank"
              key={id}
              className="w-8 h-8 bg-white rounded-full flex justify-center items-center md:mr-2"
            >
              <FontAwesomeIcon
                icon={icon}
                className="text-base"
                style={{ color: color }}
              />
            </a>
          ))}
        </div>
        <div>
          <p className="text-xs font-thin text-white text-center">
            © navanee.com All rights reserved. Courtesy: Images www.pexels.com,
            Icons www.flaticon.com
          </p>
        </div>
      </div>
    </section>
  );
};

export default Education;
