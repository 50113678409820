const Experience = () => {
  return (
    <section className="flex flex-row justify-center items-center -mt-28 z-50">
      <div
        className="card w-5/6 md:w-4/5 bg-creamwhite py-8 px-4 md:px-10 shadow-md rounded-md text-center"
        data-aos="fade-up"
      >
        <h2 className="text-2xl text-lightblack font-medium mb-4">
          16+ years of Experience
        </h2>
        <p className="text-sm text-darkblack font-normal text-justify md:text-center leading-relaxed">
          Since Oct 2004, I am into the Design field. I have over 16 years of
          experience in Graphics design, UI design, Visual design and UX Design
          with reputed information technologies companies. Experience in
          handling a diverse range of design related activities namely:
          planning, execution, testing and client interaction and team
          management.
        </p>
      </div>
    </section>
  );
};

export default Experience;
