import React from "react";

const ButtonContainer = ({ title, pointer = true, onClick }) => {
  return (
    <button
      className={`border-2 border-primary py-2 px-5 text-primary text-sm font-semibold capitalize rounded-md md:hover:shadow-md ${
        pointer ? "cursor-pointer" : "cursor-default"
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default ButtonContainer;
